import React, { useState, useEffect, useLayoutEffect } from "react";
import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import image from "./demo.png";
import base64 from "base-64";
import ReactDOM from "react-dom";
import NameNWallet from "./nameNWallet";
import NFTContract from "./contracts/NFTContract";
import Info from "./info";
// import Web3 from "web3";
import axios from "axios";
import About from "./about";
import WalletConnect from "@walletconnect/web3-provider";
import "./App.css";
import Contract from "web3-eth-contract";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

const INFURA_ID = "9b2ce6abf15f4577a2f233789eda4239";

export const providerOptions = {
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "9b2ce6abf15f4577a2f233789eda4239",
    },
  },
};

//100 years DONE
//year - 4
//1,2,3 years - OG strikethrough
//1,2,3 years - OG
//mobile trustwallet DONE
//desketop wallet connect DONE

const contractAddress = Info.address;

document.body.style = "background: #EEEEEE";

const web3Modal = new Web3Modal({
  providerOptions, // required
});

const svgPlaceholder = `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: #C4C4C4; font-family: sans-serif; font-size: 14px; font-weight: 300;}</style><rect width="100%" height="100%" fill="white" /><text x="112" y="180" class="base">Select a year above</text></svg>`;

export default function Crypto() {
  const [isFAQ, setIsFAQ] = useState(false);
  const [web33, setWeb33] = useState(null);
  const [canMint, setCanMint] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintedTokenId, setMintedTokenId] = useState("0");
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [isOG, setIsOG] = useState(false);
  const [OGnumber, setOGNumber] = useState(0);
  const [isConnected, setIsConnected] = useState(false);

  const connectWallet = async () => {
    try {
      if (!isConnected) {
        await web3Modal.clearCachedProvider();
        const provider = await web3Modal.connect();
        console.log(provider);
        const library = new ethers.providers.Web3Provider(provider);
        setProvider(provider);
        console.log(provider.selectedAddress);
        setLibrary(library);
        console.log(library.provider);
        setIsConnected(true);
        const link = `https://api.etherscan.io/api?module=account&action=txlist&address=${provider.selectedAddress}&startblock=0&endblock=99999999&page=0&sort=asc`;
        axios
          .get(link)
          .then(async function (response) {
            console.log(response.data);
            console.log(response.data.result[0].timeStamp);
            var date = new Date(response.data.result[0].timeStamp * 1000);
            var year = date.getFullYear();
            setCanMint(true);
            console.log(year);
            document.getElementById(
              "message"
            ).innerText = `You’ve interacted the first time in ${year} with the Ethereum blockchain.😎 🥳`;

            Contract.setProvider(provider);
            var contract = new Contract(NFTContract, contractAddress);
            console.log(contract);
            let artResponse = await contract.methods.makeArt(year).call();
            setOGNumber(year);
            console.log(artResponse);
            document.getElementById("art").src =
              "data:image/svg+xml;base64," + base64.encode(artResponse);
          })
          .catch(function (error) {
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mint = async () => {
    try {
      if (isConnected && canMint) {
        Contract.setProvider(provider);
        var contract = new Contract(NFTContract, contractAddress);
        console.log(contract);
        console.log(provider.selectedAddress);
        contract.methods
          .mint(OGnumber)
          .send({
            from: provider.selectedAddress,
            gas: 200000,
            value: 20000000000000000,
          })
          .once("transactionHash", function (hash) {
            document.getElementById("mintButtonText").innerText = "Minting...";
            console.log("minting");
            setTransactionHash(hash);
          })
          .then(async function (result) {
            document.getElementById("infoText").innerText =
              "Congrats, ur Crypto OG Badge NFT is minted!";
            document.getElementById("mintButton").classList.remove("d-block");
            document.getElementById("mintButton").classList.add("d-none");
            document.getElementById("mintedlLinks").classList.add("d-block");
            document.getElementById("mintedlLinks").classList.remove("d-none");
            let generatedToken = result.events.Transfer.returnValues.tokenId;
            setMintedTokenId(generatedToken);
            console.log(generatedToken);
          })
          .catch((x) => {
            document.getElementById("mintButtonText").innerText = "Error";
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function parentMethod() {
    setIsFAQ(false);
  }

  async function authenticate() {
    console.log("auth");
  }

  function about() {
    setIsFAQ(true);
  }

  function generateArt(yearValue) {
    if (yearValue == 1 || yearValue == 2 || yearValue == 3) {
      setIsOG(false);
      return `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; text-decoration: line-through }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OG</text></svg>`;
    }
    if (yearValue == 4) {
      setIsOG(true);
      return `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OG</text></svg>`;
    }
    setIsOG(true);
    let score = yearValue - 3;
    let OGString = "";
    let textYArray = [35, 65, 95, 125, 155, 185, 215, 245, 275, 305];
    let OGArray = ["", "", "", "", "", "", "", "", "", "", ""];
    let rowCounter = 0;
    rowCounter = Math.floor((score + 1) / 13);
    for (let i = 0; i < score; i++) {
      OGArray[Math.floor(i / 13)] = OGArray[Math.floor(i / 13)] + "O";
      if (i + 1 == score) {
        OGArray[Math.floor((i + 1) / 13)] =
          OGArray[Math.floor((i + 1) / 13)] + "G";
      }
    }

    let texts = "";

    for (let i = 0; i <= rowCounter; i++) {
      texts =
        texts +
        '<text x="10" y="' +
        textYArray[i] +
        '" class="base">' +
        OGArray[i] +
        "</text>";
    }

    let finalStrng =
      '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; }</style><rect width="100%" height="100%" fill="white" />';

    console.log(texts);
    OGString = OGString + "G";
    finalStrng = finalStrng + texts + `</svg>`;

    return finalStrng;
  }

  function choice() {
    let yearValue = parseInt(document.getElementById("select").value);
    if (yearValue == 0) {
      setCanMint(false);
      document.getElementById("art").src =
        "data:image/svg+xml;base64," + base64.encode(svgPlaceholder);
    } else {
      setCanMint(true);
      setOGNumber(yearValue);
      document.getElementById("art").src =
        "data:image/svg+xml;base64," + base64.encode(generateArt(yearValue));
    }
  }

  return (
    <div>
      <div className="container width-576 CRYPTO-OGtext">
        <div id="UI-div" className={isFAQ ? "d-none" : "d-block"}>
          <div className="pt-3 pb-3">
            <div>
              <div
                id="link"
                className="d-flex flex-row justify-content-between"
              >
                <p>Crypto.OGenerator</p>
                <p onClick={about} className="d-block d-md-none">
                  ☞faq
                </p>
              </div>
            </div>
            <p className="" id="message" style={{ whiteSpace: "pre-wrap" }}>
              How many years have you been involved in crypto? Connect your
              wallet to find out.
            </p>
          </div>
          <div id="connectButton" className="d-block">
            <div
              className="d-inline-block mt-1 clickable"
              style={{
                height: "48px",
                paddingTop: "5px",
                textAlign: "center",
                background: "white",
                border: "1px solid #000000",
              }}
            >
              <p onClick={connectWallet} className="px-3">
                Connect Wallet
              </p>
            </div>
          </div>
          <img
            width="3000px"
            id="art"
            className="img-fluid pt-3"
            src={
              "data:image/svg+xml;base64," +
              base64.encode(
                `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: #C4C4C4; font-family: monospace; font-size: 12px; font-weight: 400;}</style><rect width="100%" height="100%" fill="white" /><text x="25" y="180" class="base">Connect Wallet to Create Crypto Badge NFT</text></svg>`
              )
            }
            alt=""
          />

          <p className="pt-2" id="infoText">
            Mint Crypto OG Badge NFT: 0.02ETH
          </p>
          <div id="mintButton" className="d-block">
            <div
              onClick={mint}
              className="d-inline-block mt-3"
              style={{
                height: "48px",
                paddingTop: "5px",
                textAlign: "center",
                background: canMint ? "white" : "#EEEEEE",
                border: "1px solid #000000",
              }}
            >
              <p id="mintButtonText" className="px-3">
                Mint
              </p>
            </div>
          </div>
          <div id="mintedlLinks" className="d-none mt-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://etherscan.io/tx/${transactionHash}`}
              className="pr-1"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3">Etherscan</p>
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://zora.co/collections/${contractAddress.toLowerCase()}/${mintedTokenId}`}
              className="px-1 d-none"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3">Zora</p>
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://opensea.io/assets/${contractAddress.toLowerCase()}/${mintedTokenId}`}
              className="px-1 CRYPTO-OGtext"
            >
              <div
                className="d-inline-block"
                style={{
                  height: "48px",
                  paddingTop: "5px",
                  textAlign: "center",
                  background: "white",
                  border: "1px solid #000000",
                }}
              >
                <p className="px-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://opensea.io/assets/${contractAddress.toLowerCase()}/${mintedTokenId}`}
                    className="px-1 CRYPTO-OGtext"
                  >
                    OpenSea
                  </a>
                </p>
              </div>
            </a>
          </div>
          <div id="mobileInfo" className="d-block d-md-none pb-3">
            <p className="pt-4">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://etherscan.io/address/${contractAddress.toLowerCase()}`}
              >
                ☞contract
              </a>
            </p>
            <p className="pt-2 d-none">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://zora.co/collections/${contractAddress.toLowerCase()}`}
              >
                ☞zora
              </a>
            </p>
            <p className="pt-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/collection/crypto-og-badge"
              >
                ☞opensea
              </a>
            </p>
            <p className="pt-2 CRYPTO-OGtext">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Damjanski"
              >
                ☞twitter
              </a>
            </p>
          </div>
          <div className="d-none d-md-block">
            <div
              id="link"
              className="py-4 d-flex flex-row justify-content-between"
            >
              <p className="clickable" onClick={about}>
                ☞faq
              </p>
              <p className="">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://etherscan.io/address/${contractAddress.toLowerCase()}`}
                >
                  ☞contract
                </a>
              </p>
              <p className="d-none">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://zora.co/collections/${contractAddress.toLowerCase()}`}
                >
                  ☞zora
                </a>
              </p>
              <p className="CRYPTO-OGtext">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://opensea.io/collection/crypto-og-badge"
                >
                  ☞opensea
                </a>
              </p>
              <p className="CRYPTO-OGtext ">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/Damjanski"
                >
                  ☞twitter
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="About-div" className={isFAQ ? "d-block" : "d-none"}>
          <About parentMethod={() => parentMethod()}></About>
        </div>
      </div>
    </div>
  );
}

{
  /* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350"><style>.base { fill: black; font-family: sans-serif; font-size: 32px; }</style><rect width="100%" height="100%" fill="white" /><text x="10" y="35" class="base">OOOOOOOOOOOOG</text></svg> */
}
