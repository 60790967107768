import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MoralisProvider } from "react-moralis";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

ReactDOM.render(
  <MoralisProvider
    appId="yGYuNv9IbXp7ZjCUthhYhfREposUazn8p6BVDo7L"
    serverUrl="https://sv3qzr5k8p8n.usemoralis.com:2053/server"
  >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  </MoralisProvider>,
  document.getElementById("root")
);
